/**
 |--------------------------------------------------
 | DEFAULT TYPE ACTION
 |--------------------------------------------------
 */

// AUTHENTICATION
export const LOGOUT = "LOGOUT";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FAILURE_TOKEN = "FAILURE_TOKEN";

export const ROLES = "ROLES";
export const GET_ROLES = "GET_ROLES";

export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// CLEAR
export const CLEAR_STORE = "CLEAR_STORE";

export const LOGIN_BY_GOOGLE_REQUEST = "LOGIN_BY_GOOGLE_REQUEST";
export const LOGIN_BY_GOOGLE_SUCCESS = "LOGIN_BY_GOOGLE_SUCCESS";
export const LOGIN_BY_GOOGLE_FAILURE = "LOGIN_BY_GOOGLE_FAILURE";

// USER DATA
export const GAME_INDEX_REQUEST = "GAME_INDEX_REQUEST";
export const GAME_INDEX_SUCCESS = "GAME_INDEX_SUCCESS";
export const GAME_INDEX_FAILURE = "GAME_INDEX_FAILURE";

//TASK
export const TASK_INDEX_REQUEST = "TASK_INDEX_REQUEST";
export const TASK_INDEX_SUCCESS = "TASK_INDEX_SUCCESS";
export const TASK_INDEX_FAILURE = "TASK_INDEX_FAILURE";

export const TASK_DETAIL_REQUEST = "TASK_DETAIL_REQUEST";
export const TASK_DETAIL_SUCCESS = "TASK_DETAIL_SUCCESS";
export const TASK_DETAIL_FAILURE = "TASK_DETAIL_FAILURE";

export const TASK_CREATE_ATTACHMENT_REQUEST = "TASK_CREATE_ATTACHMENT_REQUEST";
export const TASK_CREATE_ATTACHMENT_SUCCESS = "TASK_CREATE_ATTACHMENT_SUCCESS";
export const TASK_CREATE_ATTACHMENT_FAILURE = "TASK_CREATE_ATTACHMENT_FAILURE";

export const TASK_DELETE_ATTACHMENT_REQUEST = "TASK_DELETE_ATTACHMENT_REQUEST";
export const TASK_DELETE_ATTACHMENT_SUCCESS = "TASK_DELETE_ATTACHMENT_SUCCESS";
export const TASK_DELETE_ATTACHMENT_FAILURE = "TASK_DELETE_ATTACHMENT_FAILURE";

export const TASK_UPDATE_DESCRIPTION_REQUEST = "TASK_UPDATE_DESCRIPTION_REQUEST";
export const TASK_UPDATE_DESCRIPTION_SUCCESS = "TASK_UPDATE_DESCRIPTION_SUCCESS";
export const TASK_UPDATE_DESCRIPTION_FAILURE = "TASK_UPDATE_DESCRIPTION_FAILURE";

export const TASK_CREATE_SUBTASK_REQUEST = "TASK_CREATE_SUBTASK_REQUEST";
export const TASK_CREATE_SUBTASK_SUCCESS = "TASK_CREATE_SUBTASK_SUCCESS";
export const TASK_CREATE_SUBTASK_FAILURE = "TASK_CREATE_SUBTASK_FAILURE";

export const TASK_INDEX_COMMENT_REQUEST = "TASK_INDEX_COMMENT_REQUEST";
export const TASK_INDEX_COMMENT_SUCCESS = "TASK_INDEX_COMMENT_SUCCESS";
export const TASK_INDEX_COMMENT_FAILURE = "TASK_INDEX_COMMENT_FAILURE";

export const TASK_ASSIGN_USER_TASK_REQUEST = "TASK_ASSIGN_USER_TASK_REQUEST";
export const TASK_ASSIGN_USER_TASK_SUCCESS = "TASK_ASSIGN_USER_TASK_SUCCESS";
export const TASK_ASSIGN_USER_TASK_FAILURE = "TASK_ASSIGN_USER_TASK_FAILURE";

export const TASK_CREATE_COMMENT_REQUEST = "TASK_CREATE_COMMENT_REQUEST";
export const TASK_CREATE_COMMENT_SUCCESS = "TASK_CREATE_COMMENT_SUCCESS";
export const TASK_CREATE_COMMENT_FAILURE = "TASK_CREATE_COMMENT_FAILURE";

export const TASK_UPDATE_COMMENT_REQUEST = "TASK_UPDATE_COMMENT_REQUEST";
export const TASK_UPDATE_COMMENT_SUCCESS = "TASK_UPDATE_COMMENT_SUCCESS";
export const TASK_UPDATE_COMMENT_FAILURE = "TASK_UPDATE_COMMENT_FAILURE";

export const TASK_DELETE_COMMENT_REQUEST = "TASK_DELETE_COMMENT_REQUEST";
export const TASK_DELETE_COMMENT_SUCCESS = "TASK_DELETE_COMMENT_SUCCESS";
export const TASK_DELETE_COMMENT_FAILURE = "TASK_DELETE_COMMENT_FAILURE";

export const SUBTASK_UPDATE_REQUEST = "SUBTASK_UPDATE_REQUEST";
export const SUBTASK_UPDATE_SUCCESS = "SUBTASK_UPDATE_SUCCESS";
export const SUBTASK_UPDATE_FAILURE = "SUBTASK_UPDATE_FAILURE";

export const TASK_CREATE_REQUEST = "TASK_CREATE_REQUEST";
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS";
export const TASK_CREATE_FAILURE = "TASK_CREATE_FAILURE";

export const TASK_DELETE_REQUEST = "TASK_DELETE_REQUEST";
export const TASK_DELETE_SUCCESS = "TASK_DELETE_SUCCESS";
export const TASK_DELETE_FAILURE = "TASK_DELETE_FAILURE";

export const TASK_CREATE_TAG_REQUEST = "TASK_CREATE_TAG_REQUEST";
export const TASK_CREATE_TAG_SUCCESS = "TASK_CREATE_TAG_SUCCESS";
export const TASK_CREATE_TAG_FAILURE = "TASK_CREATE_TAG_FAILURE";

export const TASK_INDEX_TAG_REQUEST = "TASK_INDEX_TAG_REQUEST";
export const TASK_INDEX_TAG_SUCCESS = "TASK_INDEX_TAG_SUCCESS";
export const TASK_INDEX_TAG_FAILURE = "TASK_INDEX_TAG_FAILURE";

export const TASK_UPDATE_TAG_REQUEST = "TASK_UPDATE_TAG_REQUEST";
export const TASK_UPDATE_TAG_SUCCESS = "TASK_UPDATE_TAG_SUCCESS";
export const TASK_UPDATE_TAG_FAILURE = "TASK_UPDATE_TAG_FAILURE";

export const TASK_DELETE_TAG_REQUEST = "TASK_DELETE_TAG_REQUEST";
export const TASK_DELETE_TAG_SUCCESS = "TASK_DELETE_TAG_SUCCESS";
export const TASK_DELETE_TAG_FAILURE = "TASK_DELETE_TAG_FAILURE";

export const TASK_NOTIFY_INDEX_REQUEST = "TASK_NOTIFY_INDEX_REQUEST";
export const TASK_NOTIFY_INDEX_SUCCESS = "TASK_NOTIFY_INDEX_SUCCESS";
export const TASK_NOTIFY_INDEX_FAILURE = "TASK_NOTIFY_INDEX_FAILURE";

export const TASK_NOTIFY_UPDATE_REQUEST = "TASK_NOTIFY_UPDATE_REQUEST";
export const TASK_NOTIFY_UPDATE_SUCCESS = "TASK_NOTIFY_UPDATE_SUCCESS";
export const TASK_NOTIFY_UPDATE_FAILURE = "TASK_NOTIFY_UPDATE_FAILURE";
// Tag
export const TAG_INDEX_REQUEST = "TAG_INDEX_REQUEST";
export const TAG_INDEX_SUCCESS = "TAG_INDEX_SUCCESS";
export const TAG_INDEX_FAILURE = "TAG_INDEX_FAILURE";

export const TAG_DELETE_REQUEST = "TAG_DELETE_REQUEST";
export const TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS";
export const TAG_DELETE_FAILURE = "TAG_DELETE_FAILURE";

// User
export const USER_INDEX_REQUEST = "USER_INDEX_REQUEST";
export const USER_INDEX_SUCCESS = "USER_INDEX_SUCCESS";
export const USER_INDEX_FAILURE = "USER_INDEX_FAILURE";

export const UPDATE_USER_PERMISSION_REQUEST = "UPDATE_USER_PERMISSION_REQUEST";
export const UPDATE_USER_PERMISSION_SUCCESS = "UPDATE_USER_PERMISSION_SUCCESS";
export const UPDATE_USER_PERMISSION_FAILURE = "UPDATE_USER_PERMISSION_FAILURE";

export const REPORT_INDEX_REQUEST = "REPORT_INDEX_REQUEST";
export const REPORT_INDEX_SUCCESS = "REPORT_INDEX_SUCCESS";
export const REPORT_INDEX_FAILURE = "REPORT_INDEX_FAILURE";