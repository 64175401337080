import React, {Component} from 'react';
import {isLogin} from '../../utils';
import {
    Redirect,
} from "react-router-dom";
import jwt_decode from 'jwt-decode';

class CheckIsLogin extends Component {

    componentDidMount() {
        this.interval = setInterval(() => {
            const access_token = localStorage.getItem("access_token");
            
            if(access_token)
                if(jwt_decode(access_token).exp * 1000 <= (new Date()).getTime()) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('token_type');
                    localStorage.removeItem('auth2');
                    window.location = '/login';
                }
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    canAccessWithoutLogin(){
        let ignoreUri = [
            'auth/google',
            'login/simple',
            'login/interact',
            'register/interact',
        ];

        for(let i = 0;i<ignoreUri.length;i++){
            let uri = ignoreUri[i];
            let indexOf = window.location.href.indexOf(uri);
            if(indexOf > -1) return  true;
        }

        return false;
    }

    render() {
        if (isLogin() || this.canAccessWithoutLogin()) {
            return null;
        }

        return (<Redirect to='/login'/>);
    }
}

export {CheckIsLogin};
