import React, {Component} from 'react';
import ReactLoading from 'react-loading';

class Loading extends Component {

    render() {
        return (

            <div className="text-center loading_data">
                <ReactLoading type={"bars"} color={"#000"} height={40} width={40}/>
            </div>

        )
    }
}

export default Loading;