import { SERVER_API_AUTH2, USER_INFO_AUTH2 } from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";

const GetMyAuthAction = (token) => ({
    [RSAA]: {
        endpoint: `${SERVER_API_AUTH2}/api/me`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        types: [
            "REQUEST_GET_MY_AUTH",
            {
                type: "GET_MY_AUTH",
                payload: async (action, state, res) => {
                    const contentType = res.headers.get("Content-Type");
                    if (contentType && ~contentType.indexOf("json")) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_AUTH2);
                        });
                        if (res.status === 200)
                            return {
                                code: 0,
                                type: "GET_MY_AUTH",
                            };
                    }
                },
            },
            "FAILURE_GET_MY_AUTH",
        ],
    },
});

export { GetMyAuthAction };
