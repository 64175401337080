import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { issetFiled, is_login } from "../../../utils";
import { connect } from "react-redux";
import Loading from "../../Loading/Loading";
import { i18n } from "../../../language/i18n";

import GoogleLogin from "react-google-login";
import { GoogleLoginAction } from "../../../actions/login/GoogleLoginAction";
import { GetMyAuthAction } from "../../../actions/login/GetMyAuthAction";
import {GetSensorTowerDataAction} from "../../../actions/login/GetSensorTowerDataAction";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_login: is_login(),
        };
    }

    loading = () => <Loading />;

    /**
     * Custom Google Login Button
     * @returns void
     */
    GLogin = () => {
        const onSuccess = (googleUser) => {
            let obj = {};
            obj.id = googleUser.profileObj.googleId;
            obj.name = googleUser.profileObj.name;
            obj.email = googleUser.profileObj.email;
            obj.imageUrl = googleUser.profileObj.imageUrl;
            obj.tokenId = googleUser.tokenId;
            obj.accessToken = googleUser.accessToken;

            this.props.googleLoginAction({
                id_token: googleUser.tokenId,
            });
        };

        return (
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                    <Button
                        onClick={renderProps.onClick}
                        color="primary"
                        className="px-4 btn btn-block"
                    >
                        <i className="fa fa-google" aria-hidden="true">
                            {i18n.t("  Sign in with Google")}
                        </i>
                    </Button>
                )}
                buttonText="Sign in with Google"
                onSuccess={onSuccess}
            />
        );
    };

    /**
     * Save Auth2 user in localStorage
     * @param {*} nextProps
     * @param {*} nextContext
     */
    async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const token_type = issetFiled("token_type")
            ? JSON.parse(localStorage.getItem("token_type"))
            : null;
        const access_token = issetFiled("access_token")
            ? JSON.parse(localStorage.getItem("access_token"))
            : null;
        const token = token_type + " " + access_token;

        if (nextProps.user_login.data) {
            if (nextProps.user_login.data.code === 0) {
                if (nextProps.user_login.data.type === "login") {
                    if (nextProps.user_login.data.code === 0) {
                        await this.props.getMyAuthAction(token);
                        await this.props.getSensorTowerDataAction(token);
                        this.setState({ is_login: true });
                    } else {
                        this.setState({
                            disabled_btn_login: false,
                            message_error: nextProps.user_login.data.message,
                        });
                    }
                }
            } else
                this.setState({
                    disabled_btn_login: false,
                    message_error: i18n.t("account_does_not_have_access"),
                });
        }
    }

    render() {
        const { is_login } = this.state;
        const { i18n } = this.props;
        if (is_login) {
            return <Redirect to="/dashboard" />;
        }

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h1>{i18n.t("Login")}</h1>
                                    {this.GLogin()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_login: state.user_login_reducer,
    };
};

export default connect(mapStateToProps, {
    googleLoginAction: GoogleLoginAction,
    getMyAuthAction: GetMyAuthAction,
    getSensorTowerDataAction: GetSensorTowerDataAction,
})(Login);
