import {
    GET_ROLES,CLEAR_STORE
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const roles_reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ROLES:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    roles_reducer
}