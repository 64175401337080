import { SERVER_API_AUTH2 } from "../../constans";
import { LOGIN_BY_GOOGLE_FAILURE, LOGIN_BY_GOOGLE_SUCCESS } from "../../types";
import RSAA from "redux-api-middleware/es/RSAA";
import headers from "../../utils/headers";

/**
 * LOGIN_BY_GOOGLE
 * @param dataPost
 */

const GoogleLoginAction = (dataPost) => ({
    [RSAA]: {
        endpoint: `${SERVER_API_AUTH2}/oauth/google`,
        method: "POST",
        headers: headers(),
        body: () => {
            return JSON.stringify(dataPost);
        },
        types: [
            "LOGIN_BY_GOOGLE_REQUEST",
            {
                type: LOGIN_BY_GOOGLE_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get("Content-Type");
                    if (contentType && ~contentType.indexOf("json")) {
                        await res.json().then((json) => {
                            localStorage.setItem(
                                "token_type",
                                JSON.stringify(json.data.token_type)
                            );
                            localStorage.setItem(
                                "access_token",
                                JSON.stringify(json.data.access_token)
                            );
                        });

                        if (res.status === 200)
                            return {
                                code: 0,
                                type: "login",
                            };
                    }
                },
            },
            {
                type: LOGIN_BY_GOOGLE_FAILURE,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get("Content-Type");
                    let message = "";
                    if (contentType && ~contentType.indexOf("json")) {
                        await res.json().then((json) => {
                            message = json.message;
                        });

                        return {
                            code: 1,
                            type: "login",
                            message: message,
                        };
                    }
                },
            },
        ],
    },
});

export { GoogleLoginAction };
