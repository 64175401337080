import i18n from 'i18next';
import {en} from './en';
import {vn} from './vn';

i18n.init({
    resources: {
        en: {translations: en},
        vn: {translations: vn}
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: true,
        formatSeparator: ','
    },
    react: {wait: true}
});

export {i18n};