
/**
 |--------------------------------------------------
 | CHECK USER LOGIN
 |--------------------------------------------------
 * @return {boolean}
 */
export default function isLogin() {
    const access_token = localStorage.getItem('access_token');
    const token_type = localStorage.getItem('token_type');
    return access_token !== null && token_type !== null;
}
