import {
    LOGOUT,
    CLEAR_STORE,
    REFRESH_TOKEN,
    LOGIN_BY_GOOGLE_SUCCESS,
} from "../../types";

/**
 * user_login_reducer
 * @param {*} state
 * @param {*} action
 */
const user_login_reducer = (state = {}, action) => {
    switch (action.type) {
        case REFRESH_TOKEN:
        case LOGOUT:
        case LOGIN_BY_GOOGLE_SUCCESS:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export { user_login_reducer };
