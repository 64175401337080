import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import { LoadingSpinner } from "./views/Loading/LoadingSpinner";
import { CheckIsLogin } from "./containers/Users/CheckIsLogin";
import Login from "./views/Pages/Login/Login";
import Moment from 'react-moment';

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
// const Login = React.lazy(() => import("./views/Pages/Login"));

const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

Moment.globalFormat = 'Y/MM/DD H:m:s';

class App extends Component {
    render() {
        return (
            <React.Suspense fallback={""}>
                <ToastContainer />

                <BrowserRouter>
                    <CheckIsLogin />
                    <LoadingSpinner />
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            name="Login Page"
                            render={(props) => (
                                <Login i18n={this.props.i18n} {...props} />
                            )}
                        />

                        <Route
                            exact
                            path="/404"
                            name="Page 404"
                            render={(props) => <Page404 {...props} />}
                        />
                        <Route
                            exact
                            path="/500"
                            name="Page 500"
                            render={(props) => <Page500 {...props} />}
                        />
                        <Route
                            path="/"
                            name="Home"
                            render={(props) => (
                                <DefaultLayout
                                    i18n={this.props.i18n}
                                    {...props}
                                />
                            )}
                        />
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        );
    }
}

export default App;
