import {GAME_INDEX_SUCCESS} from "../types";
import {TOP_RANKING_INDEX_SUCCESS} from "../containers/SensorTower/TopRanking/Action/TopRankingActionType";
import {HOT_TREND_INDEX_SUCCESS} from "../containers/SensorTower/HotTrend/Action/HotTrendActionType";
import {ST_APP_INDEX_SUCCESS} from "../containers/SensorTower/App/Action/AppActionType";
import {ST_TOP_APP_INDEX_SUCCESS} from "../containers/SensorTower/TopApp/Action/TopAppActionType";
import {TASK_INDEX_SUCCESS} from "../types/index";

/**
 * @param {*} state
 * @param {*} action
 */
const PaginateReducer = (state = {}, action) => {
    if (action.payload === undefined) return { ...state };

    switch (action.type) {
        case ST_TOP_APP_INDEX_SUCCESS:
        case ST_APP_INDEX_SUCCESS:
        case HOT_TREND_INDEX_SUCCESS:
        case TOP_RANKING_INDEX_SUCCESS:
        case TASK_INDEX_SUCCESS:
            let key = action.type;
            if (state[key] === undefined) state[key] = {};

            state[key] = action.payload.data;
            return { ...state, action };

        case GAME_INDEX_SUCCESS:

            return { ...state, action };
        default:
            return { ...state };
    }
};

export { PaginateReducer };
