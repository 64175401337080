import issetFiled from "./issetFiled";

/**
 |--------------------------------------------------
 | Headers
 |--------------------------------------------------
 */
export default function headers() {
    const token_type = issetFiled("token_type")
        ? JSON.parse(localStorage.getItem("token_type"))
        : null;
    const access_token = issetFiled("access_token")
        ? JSON.parse(localStorage.getItem("access_token"))
        : null;
    const token = token_type + " " + access_token;

    return {
        "Content-Type": "application/json",
        Authorization: token,
    };
}
