/**
|--------------------------------------------------
| CHECK USER LOGIN
|--------------------------------------------------
*/
export default function is_login() {
    try {
        //TODO: remove this method asap
        const access_token = localStorage.getItem("access_token");

        return access_token !== null;
    } catch (error) {
        return false;
    }
}
