/**
 |--------------------------------------------------
 | ALL REDUCERS
 |--------------------------------------------------
 */
import { combineReducers } from "redux";
import { user_login_reducer } from "./login_register/LoginRegisterReducer";
import { roles_reducer } from "./RolesReducer";
import { PaginateReducer } from "./PaginateReducer";
import { ApiErrorMessageReducer } from "./ApiErrorMessageReducer";

const reducer = combineReducers({
    user_login_reducer,
    roles_reducer,
    PaginateReducer,
    ApiErrorMessageReducer,
});

export default reducer;
