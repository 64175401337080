/**
 |--------------------------------------------------
 | CLIENT CONFIG
 |--------------------------------------------------
 */

import {BASE_URL, SERVER_API_AUTH2, SERVER_ENV, SERVER_GBD,} from "./api_config";

export {SERVER_API_AUTH2, BASE_URL, SERVER_ENV, SERVER_GBD};

export const USER_INFO_AUTH2 = "auth2";
export const USER_INFO_SENSOR_TOWER = "sensor_tower";
