import {USER_INFO_SENSOR_TOWER} from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import {SERVER_SENSOR_TOWER_API} from "../../constans/api_config";
import {ME_ST_ERROR, ME_ST_REQUEST, ME_ST_SUCCESS} from "./LoginActionType";
import userInfoSave from "../../utils/userInfoSave";

const GetSensorTowerDataAction = (token) => ({
    [RSAA]: {
        endpoint: `${SERVER_SENSOR_TOWER_API}/api/me`,
        method: "GET",
        headers: {"Content-Type": "application/json", Authorization: token},
        types: [
            ME_ST_REQUEST,
            {
                type: ME_ST_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get("Content-Type");
                    if (contentType && ~contentType.indexOf("json")) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_SENSOR_TOWER);
                        });
                        if (res.status === 200)
                            return {
                                code: 0,
                                type: "GET_MY_AUTH",
                            };
                    }
                },
            },
            ME_ST_ERROR,
        ],
    },
});

export {GetSensorTowerDataAction};
