import {SERVER_API_AUTH2} from '../../constans';
import {REFRESH_TOKEN, FAILURE_TOKEN} from '../../types';
import {RSAA} from 'redux-api-middleware';
import tokenSaveToLocalStorage from "../../utils/tokenSaveToLocalStorage";

/**
 * refresh_access_token_action
 */
const refresh_access_token_action = (refresh_token,user_name = null) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_AUTH2}/oauth/token`,
        method: 'POST',
        body: (() => {
            let client_id = process.env.REACT_APP_CLIENT_ID;
            let client_secret =  process.env.REACT_APP_CLIENT_SECRET;

            const formData = new FormData();
            formData.append('grant_type', 'refresh_token');
            formData.append('refresh_token', refresh_token);
            formData.append('client_id', client_id);
            formData.append('client_secret', client_secret);
            formData.append('username', user_name);
            formData.append('scope', '');
            return formData;
        }),
        types: [
            'REQUEST-TOKEN',
            {
                type: REFRESH_TOKEN,
                payload: async (action, state, res) => {

                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            tokenSaveToLocalStorage(json);
                        })
                    }
                }
            },
            FAILURE_TOKEN
        ]
    }

});

export {refresh_access_token_action}