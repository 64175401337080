/**
 |--------------------------------------------------
 | CONFIG SERVER & PORT
 |--------------------------------------------------
 */
let BASE_URL = process.env.REACT_APP_BASE_URL;
let SERVER_API_AUTH2 = process.env.REACT_APP_SERVER_API_AUTH2;
let SERVER_SENSOR_TOWER_API = process.env.REACT_APP_SENSOR_TOWER_API;
let SERVER_ENV = process.env.REACT_APP_SERVER_ENV;
let SERVER_GBD = process.env.REACT_APP_SERVER_GBD;

export { BASE_URL, SERVER_API_AUTH2, SERVER_SENSOR_TOWER_API, SERVER_ENV, SERVER_GBD };
