const vn = {
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    user_name: "User Name",
    dashboard: "Dashboard",
    games: "Games",
    title: "Title",
    url: "Url",
    category: "Category",
    description: "Description",
    rating: "Rating",
    created_at: "Created At",
};

export { vn };
