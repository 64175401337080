const en = {
    login: "Login",
    sign_in_to_your_account: "Sign In to your account",
    user_name: "User Name",
    dashboard: "Dashboard",
    games: "Games",
    name: "Name",
    link: "Link",
    category: "Category",
    description: "Description",
    rating: "Rating",
    tags: "Tags",
    dl: "Downloads",
    created_at: "Created At",
    listing_date: "Released",
    lm_rev: "Last Month Revenue",
    comments_link: "Comments",
};

export { en };
